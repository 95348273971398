// @ts-strict-ignore
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { dayjs } from "components/ui/DatePicker/date.utils";

export type MfpPlotterFilterKey = "" | "robotLdnId" | "cowUserNumber" | "tagId";

export interface WidgetState {
    customerNumber: string;
    dateFrom: string;
    dateFromCompressor: string;
    dateFromServiceAreaSupport: string;
    dateFromDevices: string;
    dateFromSMCustomerDetail: string;
    dateFromFarmScan: string;
    dateTo: string;
    date: string;
    dateToCompressor: string;
    dateToServiceAreaSupport: string;
    dateToDevices: string;
    dateToSMCustomerDetail: string;
    dateToFarmScan: string;
    year: number;
    week: number;
    deviceSerialNumber: string;
    technicianCode: string;
    farmLicenseKey: string;
    orderNumber: string;
    requests: string;
    serviceAreaId: string;
    criticalAgreementId: string;
    agreementType: string;
    deviceType: string;
    insightId: string;
    astronautType: string;
    country: string;
    countryConPer: string;
    softwareVersion: string;
    alarmDescription: string;
    deviceSoftwareVersion: string;
    hiddenComponents: Array<string>;
    updateId: string;
    projectDetailsCustomerName: string;
    robotLdnId?: number;
    visitId?: number;
    cowUserNumber?: number;
    tagId?: number;
    yearWeekDateTo: string;
    // TODO Move all MFP values into separate slice
    mfpPlotterDate?: string;
    mfpPlotterProMode: boolean;
    mfpPlotterFilterKey: MfpPlotterFilterKey;
    mfpPlotterFilterValue: string;
}

export const initialState: WidgetState = {
    customerNumber: "",
    dateFrom: dayjs().subtract(1, "month").startOf("day").format(),
    dateFromCompressor: dayjs().subtract(6, "month").startOf("day").format(),
    dateFromServiceAreaSupport: dayjs().subtract(1, "year").startOf("day").format(),
    dateFromDevices: dayjs().subtract(1, "year").startOf("day").format(),
    dateFromSMCustomerDetail: dayjs().utcOffset(0, true).startOf("day").subtract(365, "days").format(),
    dateFromFarmScan: dayjs().utcOffset(0, true).startOf("day").subtract(365, "days").format(),
    dateTo: dayjs().utcOffset(0, true).startOf("day").format(),
    date: dayjs().utcOffset(0, true).startOf("day").format(),
    dateToCompressor: dayjs().utcOffset(0, true).startOf("day").format(),
    dateToServiceAreaSupport: dayjs().utcOffset(0, true).startOf("day").format(),
    dateToDevices: dayjs().utcOffset(0, true).startOf("day").format(),
    dateToSMCustomerDetail: dayjs().utcOffset(0, true).startOf("day").format(),
    dateToFarmScan: dayjs().utcOffset(0, true).startOf("day").format(),
    yearWeekDateTo: dayjs().utcOffset(0, true).startOf("day").format(),
    year: dayjs().year(),
    week: dayjs().subtract(1, "week").week(),
    deviceSerialNumber: "",
    technicianCode: "",
    farmLicenseKey: "",
    orderNumber: "",
    requests: "0",
    serviceAreaId: "",
    criticalAgreementId: "",
    agreementType: "",
    deviceType: "",
    insightId: "",
    astronautType: "All",
    country: "All",
    countryConPer: "NL",
    softwareVersion: "All",
    alarmDescription: "",
    deviceSoftwareVersion: "",
    hiddenComponents: [],
    updateId: "",
    projectDetailsCustomerName: "",
    robotLdnId: undefined,
    visitId: undefined,
    cowUserNumber: undefined,
    tagId: undefined,
    mfpPlotterDate: undefined,
    mfpPlotterProMode: false,
    mfpPlotterFilterKey: "",
    mfpPlotterFilterValue: "",
};

interface DataPickerDatePayload {
    key: string;
    date: string;
}

type StorePropertyPayload<T extends keyof WidgetState | string> = {
    key: T;
    value: T extends keyof WidgetState ? WidgetState[T] : any;
};

const widgetSlice = createSlice({
    name: "widget",
    initialState,
    reducers: {
        setDatePickerDate: (state, { payload: { key, date } }: PayloadAction<DataPickerDatePayload>) => {
            state[key] = date;
        },
        setProperty: <T extends keyof WidgetState | string>(
            state,
            { payload: { key, value } }: PayloadAction<StorePropertyPayload<T>>
        ) => {
            state[key] = value;
        },
    },
});

export const { setDatePickerDate, setProperty } = widgetSlice.actions;
export default widgetSlice.reducer;
